.App {
    display: flex;
    flex-direction: column;
  }
  body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
}
.content {
  display: flex;
  height: 100vh;
  position: relative; /* Ensure content takes full height */
}

.map {
  flex-grow: 1;
  position: relative; /* Ensure map takes full height */
}
  @media (max-width: 768px) {
    .content {
        flex-direction: column; /* Stack sidebar below the map */
    }

    .map, .sidebar {
        height: 100vh; /* Adjust height to split screen space equally */
        flex-grow: 1; /* Disable flex-grow to maintain the height */
    }

    .sidebar {
        width: 50%; /* Ensure sidebar takes full width on mobile */
    }
}
@media (min-width: 768px) {
    .App {
    height: 100vh;
    }
}

.timeout-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Blurred background */
  z-index: 999; /* Ensure it is behind the popup but above other content */
}

.timeout-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(247, 242, 242);
  padding: 20px;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  z-index: 1000;
  text-align: center; /* Center text */
}

.timeout-popup button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #e23515; /* Button color */
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.timeout-popup button:hover {
  background-color: #990d00; /* Darker button color on hover */
}
.timeout-popup h2 {
  margin-top: 0;
  color: #000000; /* Red color for warning */
}
#iw-container {
  padding: 0 !important;
  margin: 0 !important;
}
#iw-container .iw-title {
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 22px;
  font-weight: 400;
  padding: 10px;
  background-color: #3b3b3b;
  color: white;
  margin: 1px;
  border-radius: 2px 2px 0 0;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}

#iw-container .iw-address {
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 11px; /* Smaller font size for address */
  font-weight: 300;
  padding: 5px 10px;
  background-color: #3b3b3b; /* Same background color as title */
  color: white;
  margin: 0 1px 1px 1px; /* Adjust margins to fit under the title */
  padding-top: 0px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
#iw-container .iw-content {
  font-family: 'Open Sans Condensed', sans-serif;
  font-size: 14px;
  font-weight: 300;
  padding: 10px;
  background-color: #fff;
  color: #000;
  margin: 0 1px 1px 1px; /* Same margins as the address */
  border-top: none; /* Remove the top border to connect with the title */
  border-radius: 0 0 2px 2px; /* Rounded corners for the bottom */
  box-shadow: 0 1px 6px rgba(178, 178, 178, 0.6);
}
.gm-style-iw {
  width: 350px !important;
  top: 0 !important;
  left: 0 !important;
  background-color: #fff;
  box-shadow: 0 1px 6px rgba(178, 178, 178, 0.6);
  border-radius: 2px 2px 0 0;
  z-index: 10000;
}

.marker-icon-shadow {
  filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.3));
}